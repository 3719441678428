import { GeolocationContext } from '@components/hooks/GeolocationProvider/index.client'
import { Icon } from '@components/primitives/Icon'
import { IconPrefix } from '@components/primitives/Icon/IconPrefix'
import { Text } from '@components/primitives/Text'
import { Tooltip } from '@components/primitives/Tooltip'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons/faCircleInfo'
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons/faBadgeCheck'
import { faHospital } from '@fortawesome/pro-solid-svg-icons/faHospital'
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons/faLocationDot'
import { faStethoscope } from '@fortawesome/pro-solid-svg-icons/faStethoscope'
import { isModuleCondition } from '@modules/conditions/helpers/isAuthoritativeCondition'
import { getPrimaryConditionFromTrial } from '@modules/trials/helpers/getPrimaryConditionFromTrial'
import { getPrimaryTreatmentFromTrial } from '@modules/trials/helpers/getPrimaryTreatmentNameFromTrial'
import {
  getTrialLocationInfo,
  type TrialAttributes,
} from '@modules/trials/helpers/getTrialAttributes'
import { sortLocationsByDistance } from '@modules/trials/helpers/parseLocationsFromTrial/parseTruncatedLocationsFromTrial'
import type { TrialLocationNarrow } from '@modules/trials/types/TrialAssociatedWithLocations'
import type { TrialAssociation } from '@modules/trials/types/TrialAssociation'
import uniq from 'lodash/uniq'
import { useContext, useEffect, useState } from 'react'

export const PrimaryTreatmentLabel = ({
  trial,
}: {
  trial: TrialAssociation
}) => {
  const primaryTreatment = getPrimaryTreatmentFromTrial(trial)

  const otherTreatments = uniq(
    trial.interventions
      .map((intervention) => intervention.name)
      .filter((treatment) => treatment !== primaryTreatment?.name),
  )

  const otherTreatmentCount = otherTreatments.length

  const otherTreatmentString =
    otherTreatmentCount > 0 ? ` +${otherTreatmentCount} more` : ''

  return !primaryTreatment ? null : (
    <Text
      className='text-white'
      styleName='p-small'
      value={
        <>
          <Icon className='mr-1 text-white' icon={faHospital} />
          {primaryTreatment.name}
          <Tooltip
            description={otherTreatments.map((t) => (
              <div key={t}>- {t}</div>
            ))}
            inlineTooltip={true}
            label={
              <>
                <Text
                  className='inline text-white'
                  styleName='p-small'
                  value={otherTreatmentString}
                />
                <Icon
                  className='ml-2 inline text-white'
                  icon={faCircleInfo}
                  size={'sm'}
                />
              </>
            }
            underline={false}
          />
        </>
      }
    />
  )
}

export const PrimaryConditionLabel = ({
  trial,
}: {
  trial: TrialAssociation
}) => {
  const primaryConditionLabel = getPrimaryConditionFromTrial(trial)
  const remainingConditionCount =
    trial.conditions.filter(isModuleCondition).length - 1

  return (
    <Text
      className='text-white'
      styleName='p-small'
      value={
        <IconPrefix icon={faStethoscope}>
          {primaryConditionLabel}
          {remainingConditionCount > 0 && (
            <span className='mx-2 rounded-xl bg-white/10 p-2 px-3 text-white'>
              +{remainingConditionCount} More Conditions
            </span>
          )}
        </IconPrefix>
      }
    />
  )
}

export const OtherConditionsLabel = ({
  trial,
}: {
  trial: TrialAssociation
}) => {
  const conditionCount = trial.conditions.filter(isModuleCondition).length

  if (conditionCount < 2) {
    return null
  }
  return (
    <Text
      className='text-white'
      styleName='p-small'
      value={
        <IconPrefix icon={faStethoscope}>
          Targeting {conditionCount} different conditions
        </IconPrefix>
      }
    />
  )
}

export default function ClosestLocationLabel({
  trialLocations,
}: {
  trialLocations: TrialLocationNarrow[]
}) {
  const trialAttributes = useTrialAttributes(trialLocations)

  if (!trialAttributes.NearestLocation) {
    return null
  }

  const trialInformation = `${trialAttributes.NearestLocation} ${
    trialAttributes.Distance ? ` ${trialAttributes.Distance}` : ''
  }`

  return (
    <Text
      className='text-white'
      styleName='p-small'
      value={
        <div suppressHydrationWarning>
          <Icon className='mr-1' icon={faLocationDot} />
          {trialInformation}
          {trialAttributes.NearestLocationVerified ? (
            <>
              <Icon className='ml-2 mr-1' icon={faBadgeCheck} />
              Verified
            </>
          ) : null}
        </div>
      }
    />
  )
}

export const ClosestFacility = ({
  trialLocations,
}: {
  trialLocations: TrialLocationNarrow[]
}) => {
  const userLocation = useContext(GeolocationContext)

  const [closestTrialSite, setClosestTrialSite] = useState(
    sortLocationsByDistance(trialLocations, userLocation)[0],
  )

  useEffect(() => {
    if (trialLocations) {
      setClosestTrialSite(
        sortLocationsByDistance(trialLocations, userLocation)[0],
      )
    }
  }, [trialLocations])

  return (
    <Text
      className='line-clamp-1 font-semibold text-neutral900'
      styleName='p-small-strong'
      value={closestTrialSite?.facility}
    />
  )
}

export const useTrialAttributes = (trialLocations: TrialLocationNarrow[]) => {
  const userLocation = useContext(GeolocationContext)

  const [trialAttributes, setClientAttributes] = useState<TrialAttributes>(
    getTrialLocationInfo(trialLocations, userLocation),
  )

  useEffect(() => {
    if (trialLocations) {
      setClientAttributes(getTrialLocationInfo(trialLocations, userLocation))
    }
  }, [trialLocations])

  return trialAttributes
}
